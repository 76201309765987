import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import TMKHeader from './TMKHeader';

export default function FirstDay() {

  const navigate = useNavigate();

  return (
    <div className='busses'>
        <TMKHeader/>
        <div className='nav-back'>
            <IconButton sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }} onClick={()=>navigate('/work')} style={{ backgroundColor: 'transparent' }}>
                <ArrowBackIosIcon/>
                <h2>Первый день на работе</h2>
            </IconButton>
        </div>
        <h3>Настал Ваш ПЕРВЫЙ рабочий день. С чего начать?</h3>
        <p>1. Заблаговременно, до начала рабочей смены, зайти на территорию завода через проходную.</p>
        <p>2. Посредством телефонного звонка проинформировать руководителя о том, что Вы зашли на территорию завода.</p>
        <p>3. Направиться в кладовую для получения средств индивидуальной защиты (спецодежда, обувь, бируши, перчатки и т.п.).</p>
        <p>4. Направиться к коменданту для получения личного шкафчика в раздевалке.</p>
        <p>5. Переодеться в спецодежду и направиться на участок, подойти к мастеру.</p>
        <p>6. Ознакомиться с нормативной документацией, регулирующей и регламентирующей Ваш рабочий процесс.</p>
        <p>7. Побывать на ознакомительной экскурсии по участку.</p>
        <p>8. Познакомиться с наставником.</p>
        <p>9. Ознакомиться с работой оборудования и рабочим местом.</p>
        <p>10. Познакомиться с коллективом.</p>
    </div>
  );
}