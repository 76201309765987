import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';

export default function NestedList() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className='busses'>
        <h1>Справочник</h1>
        <List
      sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '10px' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <a href="tel:88634650011">
        <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
          <ListItemText primary="Регистратура МСЧ" />
          <IconButton edge="end">
            <LocalPhoneIcon />
          </IconButton>
        </ListItemButton>
      </a>
      <a href="tel:88634650010">
        <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
          <ListItemText primary="Специалист по приему УРПП" />
          <IconButton edge="end">
            <LocalPhoneIcon />
          </IconButton>
        </ListItemButton>
      </a>
      <a href="tel:88634650065,56159">
        <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
          <ListItemText primary="Бюро пропусков" />
          <IconButton edge="end">
              <LocalPhoneIcon />
          </IconButton>
        </ListItemButton>
      </a>
      <a href="tel:88634650065">
        <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
          <ListItemText primary="Основной (многоканальный)" />
          <IconButton edge="end">
              <LocalPhoneIcon />
            </IconButton>
        </ListItemButton>
      </a>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Список по структурным подразделениям" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={!open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <a href="tel:88634650065,55675">
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
              <ListItemText primary="БОТ ТПЦ" />
              <IconButton edge="end">
                <LocalPhoneIcon />
              </IconButton>
            </ListItemButton>
        </a>
        <a href="tel:88634650065,55355">
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
            <ListItemText primary="БОТ ЭСПЦ" />
            <IconButton edge="end">
              <LocalPhoneIcon />
            </IconButton>
          </ListItemButton>
        </a>
        <a href="tel:88634650065,55666">
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
            <ListItemText primary="БОТ ТСЦ" />
            <IconButton edge="end">
              <LocalPhoneIcon />
            </IconButton>
          </ListItemButton>
        </a>
        <a href="tel:88634650065,55402">
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
            <ListItemText primary="	БОТ службы качества" />
            <IconButton edge="end">
              <LocalPhoneIcon />
            </IconButton>
          </ListItemButton>
        </a>
        <a href="tel:88634650065,55525">
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}}>
            <ListItemText primary="БОТ МЛЦ и ЭнЦ" />
            <IconButton edge="end">
              <LocalPhoneIcon />
            </IconButton>
          </ListItemButton>
        </a>
        <a href="tel:88634650065,55529">
          <ListItemButton sx={{ pl: 2 }}>
            <ListItemText primary="БОТ вспомогат. Служб" />
            <IconButton edge="end">
              <LocalPhoneIcon />
            </IconButton>
          </ListItemButton>
        </a>
        </List>
      </Collapse>
    </List>
    </div>
  );
}