import * as React from 'react';

export default function TMKHeader() {
  return (
    <div className='tmk-header'>
        <div className='tmk-text'>
          <p>Таганрогский</p>
          <p>металлургический</p>
          <p>завод</p>
        </div>
        <span><img src='https://upload.wikimedia.org/wikipedia/commons/f/fb/Tagmet_RUS.png'></img></span>
    </div>
  );
}