import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, purple } from '@mui/material/colors';
import FlagIcon from '@mui/icons-material/Flag';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      primary: {main: '#db7107'},
      secondary: purple,
    },
  });

  React.useEffect(() => {
    if (window.location.pathname.startsWith('/work')) {
      setValue('/work');
      return;
    }
    setValue(window.location.pathname);
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
        <BottomNavigation
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}
            showLabels
            value={value}
            onChange={(event, newValue) => {
               if (newValue === 'schedule') {
                return;
               }
            setValue(newValue);
            }}
        >
            <BottomNavigationAction label="Справочник" value="/phones"onClick={()=>navigate("/phones")} icon={<LocalPhoneIcon />} />
            <BottomNavigationAction label="Устройство" value="/work" onClick={()=>navigate("/work")} icon={<WorkIcon />} />
            <BottomNavigationAction label="Материалы" value="/" onClick={()=>navigate("/")} icon={<FlagIcon />} />
            <BottomNavigationAction label="Автобусы" value="/busses"onClick={()=>navigate("/busses")} icon={<DepartureBoardIcon />} />
            <BottomNavigationAction label="Навигация" value="/map"onClick={()=>navigate("/map")} icon={<LocationOnIcon />} />
        </BottomNavigation>
      </ThemeProvider>
  );
}