import './App.css';
import SimpleBottomNavigation from './Demo';
import Main from './Components/Main';
import PhoneBook from './Components/PhoneBook';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Schedule from './Components/Schedule';
import Busses from './Components/Busses';
import Map from './Components/Map';
import RoadMap from './Components/JobRoadMap';
import FirstDay from './Components/FirstDay';
import Bonuses from './Components/Bonuses';
import Cash from './Components/Cash';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/phones" element={<PhoneBook/>} />
        <Route path="/work" element={<Schedule/>} />
        <Route path="/work/roadmap" element={<RoadMap/>} />
        <Route path="/work/firstday" element={<FirstDay/>} />
        <Route path="/work/bonuses" element={<Bonuses/>} />
        <Route path="/work/cash" element={<Cash/>} />
        <Route path="/busses" element={<Busses/>} />
        <Route path="/map" element={<Map/>} />
      </Routes>
      <SimpleBottomNavigation/>
    </div>
  );
}

export default App;
