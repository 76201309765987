import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import TMKHeader from './TMKHeader';

export default function RoadMap() {

  const navigate = useNavigate();

  return (
    <div className='busses'>
        <TMKHeader/>
        <div className='nav-back'>
            <IconButton aria-label="back" onClick={()=>navigate('/work')} style={{ backgroundColor: 'transparent' }}>
                <ArrowBackIosIcon/>
                <h2>Дорожная карта</h2>
            </IconButton>
        </div>
        <h3>Вы получили пакет документов, необходимых для оформления на работу:</h3>
        <ul className="fa-ul">
          <li><span className="fa-li"><i className="fa-regular fa-check-circle"></i></span>Ваше заявление на прием (внизу Вы можете увидеть длительность периода Вашего обучения по профессии);</li>
          <li><span className="fa-li"><i className="fa-solid fa-check-circle"></i></span>лист приема (на обратной стороне Вы увидите перечень мероприятий, по исполнению которых специалисты поставят подписи в каждом пункте);</li>
          <li><span className="fa-li"><i className="fa-solid fa-check-circle"></i></span>направление для прохождении медицинского осмотра.</li>
        </ul>
        <h3>Ваши действия</h3>
        <p>1. Обратиться в Отдел кадрового администрирования для получения списка документов и справок, необходимых для трудоустройства (Заводская 3, каб. 203).</p>
        <p>2. Заказать и получить справки для прохождения обследования в заводской медико-санитарной части (психоневрологический диспансер, наркологический диспансер).</p>
        <p>3. Подготовить пакет документов, список которых Вам дали в 203 кабинете.</p>
        <p>  4. Пройти инструктаж по технике безопасности (по будням в 10-00, Заводская 3, каб. 102).</p>
            <p> 5.После выполнения вышеперечисленного необходимо обратиться в Отдел кадрового администрирования и получить временный пропуск (Заводская 3, каб. 203).</p>
            <p> 6.Пройти медицинское обследование в заводской медико-санитарной части (расположение можно увидеть на карте, напечатанной на последней странице буклета).</p>
            <p>7. Проставить подписи по пунктам далее по списку в Вашем листе приема (профсоюзный комитет, оформление карточки средств индивидуальной защиты, военно учетный стол, Отдел оценки и развития персонала, Отдел промышленной безопасности, охраны труда и производственного контроля; в структурном подразделении ознакомиться с должностной инструкцией).</p>
            <p>8. После сбора всех подписей и прохождения медицинского осмотра необходимо предоставить документы в Отдел кадрового администрирования для назначения даты выхода на работу (Заводская 3, каб. 203)</p>
        
    </div>
  );
}