import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TMKHeader from './TMKHeader';
import { IconButton } from '@mui/material';

export default function Schedule() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  return (
    <div className='busses'>
         <TMKHeader/>
        <div className='job-list'>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: '10px' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}} onClick={()=>navigate("/work/roadmap")}>
            <ListItemText primary="Дорожная карта трудоустройства"/>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemButton>
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}} onClick={()=>navigate("/work/firstday")}>
            <ListItemText primary="Первый день работы" />
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemButton>
          <ListItemButton sx={{ borderBottom: '1px solid #d4d4d4'}} onClick={()=>navigate("/work/bonuses")}>
            <ListItemText primary="Бонусы работы на ТАГМЕТЕ"/>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemButton>
          <ListItemButton onClick={()=>navigate("/work/cash")}>
            <ListItemText primary="Как повлиять на увелечение ЗП"/>
            <IconButton edge="end" onClick={()=>navigate("/work/cash")}>
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemButton>

    </List>
        </div>
        <h3>Уважаемый новый сотрудник!</h3>
        <p>
          Вы трудоустраиваетесь в АО «Таганрогский металлургический завод» и совсем скоро станете частью большой команды, в составе которой более 6000 человек. Таганрогский металлургический завод основан в 1896 году и в настоящее время является одним из самых высокотехнологичных трубных заводов в мире, выпускающим практически все виды стальных труб.
          Здесь ценят вклад каждого работника в развитие производства и саморазвитие. 
          На нашем заводе Вы сможете развить не только профессиональные навыки, но и личностные компетенции.
          Желаем Вам успешно усвоить профессиональные знания, влиться в наш дружный коллектив, достичь
          высот в профессиональном и карьерном росте.
        </p>
    </div>
  );
}