import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import TMKHeader from './TMKHeader';

export default function Cash() {

  const navigate = useNavigate();

  return (
    <div className='busses'>
        <TMKHeader/>
        <div className='nav-back'>
            <IconButton aria-label="delete" onClick={()=>navigate('/work')} style={{ backgroundColor: 'transparent' }}>
                <ArrowBackIosIcon/>
                <h2>Увеличение З.П.</h2>
            </IconButton>
        </div>
        <ul>
            <li className='test'>Получение надбавки за профмастерство (по результатам аттестации)</li>
            <li className='test'>Получение более высокого квалификационного разряда</li>
            <li className='test'>Увеличение % премиальной части в связи с ростом личной производительности труда</li>
            <li className='test'>Доплата за обучение персонала/наставническую деятельность</li>
            <li className='test'>Доплата за сбор и сдачу лома</li>
            <li className='test'>Участие в проектной рационализаторской деятельности</li>
            <li className='test'>Доплата за совмещение профессий и увеличенный объем работ</li>
            <li className='test'>Доплата за исполнение обязанностей отсутствующего работника</li>
            <li className='test'>Участие в реферальной программе «Приведи друга»</li>
            <li className='test'>Доплата за руководство бригадой</li>
            <li className='test'>Премия за участие в конкурсах профмастерства</li>
            <li className='test'>Премия за участие в научно-практической конференции</li>
            <li className='test'>Премия за участие в спартакиадах</li>
        </ul>
    </div>
  );
}