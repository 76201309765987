import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import TMKHeader from './TMKHeader';

export default function Bonuses() {

  const navigate = useNavigate();

  return (
    <div className='busses'>
        <TMKHeader/>
        <div className='nav-back'>
            <IconButton aria-label="delete" onClick={()=>navigate('/work')} style={{ backgroundColor: 'transparent' }}>
                <ArrowBackIosIcon/>
                <h2>Бонусы работы на ТАГМЕТе</h2>
            </IconButton>
        </div>
        <ul>
            <li className='test'>Стабильная заработная плата</li>
            <li className='test'>Обучение за счет работодателя</li>
            <li className='test'>Возможность получения высшего образования</li>
            <li className='test'>Условия для бесплатных занятий спортом</li>
            <li className='test'>Условия для карьерного и профессионального роста</li>
            <li className='test'>Возможность развития через включение в управленческий резерв</li>
            <li className='test'>Адресная ипотека для молодых специалистов</li>
            <li className='test'>Компенсация за наем жилья иногородним работникам</li>
            <li className='test'>Предоставление путевок в детские лагеря отдыха</li>
            <li className='test'>Возможность самореализации в молодёжной, спортивной, социальной, профессиональной, научной, рационализаторской деятельности</li>
            <li className='test'>Компенсация за оплату детского сада</li>
            <li className='test'>Подарки детям работников к Новому году, 1 сентября</li>
            <li className='test'>Возможность отдыха в Сочи, Архызе, Кавказских минеральных водах со скидкой 80%</li>
            <li className='test'>Адресная материальная помощь многодетным семьям</li>
        </ul>
    </div>
  );
}