import * as React from 'react';


export default function Map() {
    React.useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A7afefc4e81af4bd276d9f00149198b9005ee4f1f49e0db4ccbf722f430cec93e&amp;width=466&amp;height=720&amp;lang=ru_RU&amp;scroll=true";
        script.async = true;
      
        document.querySelector('.mapContainer').appendChild(script);
      
        return () => {
            if (document.querySelector('.mapContainer'))
            {
                document.querySelector('.mapContainer').removeChild(script);
            }
        }
      }, []);
  return (
    <div>
        <div className='mapContainer'></div>
    </div>
  );
}