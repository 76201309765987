import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, time1, time2, time3, time4, time5, time6) {
  return { name, time1, time1, time2, time3, time4, time5, time6 };
}

const rows = [
  createData('АБК ТПЦ участка №1', '8-00', '9-00', '10-00', '11-00', '12-00', '13-00'),
  createData('АБК ТПЦ участка №2', '8-04', '9-04', '10-04', '11-04', '12-04', '13-04'),
  createData('МСЧ - ООО "МЛЗ"', '8-08', '9-08', '10-08', '11-08', '12-08', '13-08'),
  createData('Учебный центр','8-12', '9-12', '10-12', '11-12', '12-12', '13-12'),
  createData('АБК ЭнЦ', '8-20', '9-16', '10-16', '11-16', '12-16', '13-16'),
  createData('Кафе "Минутка"', '8-20', '9-20', '10-20', '11-20', '12-20', '13-20')
];

const rows2 = [
  createData('Кафе "Минутка"', '8-30', '9-30', '10-30', '11-30', '12-30', '13-30'),
  createData('АБК ЭнЦ', '8-34', '9-34', '10-34', '11-34', '12-34', '13-34'),
  createData('Учебный центр', '8-38', '9-38', '10-38', '11-38', '12-38', '13-38'),
  createData('МСЧ - ООО "МЛЗ','8-42', '9-42', '10-42', '11-42', '12-42', '13-42'),
  createData('Кпп №13 "Стальконструкция"', '8-46', '9-46', '10-46', '11-46', '12-46', '13-46'),
  createData('АБК ТПЦ участка №1', '8-50', '9-50', '10-50', '11-50', '12-50', '13-50')
];

export default function Busses() {
  return (
    <div className='busses'>
         <h1>Расписание автобусов</h1>
        <h2>Движение автобуса к заводоуправлению</h2>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Остановочная площадка</StyledTableCell>
                    <StyledTableCell align="left">Время</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                        {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.time1}</StyledTableCell>
                    <StyledTableCell align="left">{row.time2}</StyledTableCell>
                    <StyledTableCell align="left">{row.time3}</StyledTableCell>
                    <StyledTableCell align="left">{row.time4}</StyledTableCell>
                    <StyledTableCell align="left">{row.time5}</StyledTableCell>
                    <StyledTableCell align="left">{row.time6}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>

        <h2>Движение автобуса в сторону АБК ТПЦ участка №1 от кафе "Минутка"</h2>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Остановочная площадка</StyledTableCell>
                    <StyledTableCell align="left">Время</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows2.map((row) => (
                    <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                        {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.time1}</StyledTableCell>
                    <StyledTableCell align="left">{row.time2}</StyledTableCell>
                    <StyledTableCell align="left">{row.time3}</StyledTableCell>
                    <StyledTableCell align="left">{row.time4}</StyledTableCell>
                    <StyledTableCell align="left">{row.time5}</StyledTableCell>
                    <StyledTableCell align="left">{row.time6}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>
    </div>
  );
}