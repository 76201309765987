import * as React from 'react';

export default function Main() {
  return (
    <div>
        <div className="background-image">
            <div className='tmk-logo'>
                <img className='tmk-logo' src='https://upload.wikimedia.org/wikipedia/commons/f/fb/Tagmet_RUS.png'></img>
            </div>
        <div className="widget">
            <ul>
                <li><strong><a href="https://medic.tagmet.ru/%D1%80%D0%B5%D0%B6%D0%B8%D0%BC%D1%8B-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D0%B2%D1%80%D0%B0%D1%87%D0%B5%D0%B9/">График приема МСЧ</a></strong></li>
                <li><a href='ifo.pdf' target='_blank' rel='noopener noreferrer'>Основы ТБ</a></li>
                <li><a href="https://tagmet.tmk-group.ru/tagmet_about">О предприятии</a></li>
                <li><a href="https://tagmet.tmk-group.ru/tagmet_pred">Контакты</a></li>
                <li><a href="https://tagmet.tmk-group.ru/tagmet_capacity">Производство</a></li>
                <li><a href="https://tagmet.tmk-group.ru/tagmet_personal">Наши люди</a></li>
                <li><a href="https://tagmet.tmk-group.ru/tagmet_news">Новости</a></li>
            </ul>
        </div>
            <div className='social-media'>
                <a href="https://vk.com/tmkgroupru" className="fa fa-vk"></a>
                <a href="https://www.youtube.com/@TMKgroupcom" className="fa fa-youtube"></a>
                <a href="https://ok.ru/tmk.group" className="fa fa-odnoklassniki"></a>
                <a href="https://t.me/tmk_group" className="fa fa-telegram"></a>
            </div>
        </div>
    </div>
  );
}